import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import OverviewFlow from "../components/flow/OverviewFlow.component";
import { useNavigate } from "react-router-dom";
import { routerPaths } from "../router/routerPaths";
import { useSelector } from "react-redux";
import ListTables from "shared-components/src/components/displayData/ListTables.component";

const OverviewPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 767px");

  const waterSystems = useSelector((state) => state?.waterSystems?.data);
  const sensors = waterSystems
    ? waterSystems.flatMap((waterSystem) =>
        (waterSystem?.attributes?.sensors?.data || []).map((sensor) => ({
          ...sensor,
          system: waterSystem,
        }))
      )
    : [];

  // const [sensors, setSensors] = useState(
  //   waterSystem?.attributes?.sensors?.data ?? [],
  // );

  const sensorsTable = sensors?.map((sensor) => ({
    [t("overview-page.sensor-name")]: sensor?.attributes?.name,
    [t("overview-page.sensor-system")]: sensor?.system?.attributes?.name,
    [t("overview-page.sensor-type")]: t(sensor?.attributes?.type),
    eui: sensor?.attributes?.eui,
    [t(
      "overview-page.sensor-position"
    )]: `lat: ${sensor?.attributes?.position?.[0]}, lng: ${sensor?.attributes?.position?.[1]}`,
  }));

  return (
    <Fragment>
      <h3>{t("overview-page.title")}</h3>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: "20px" }}
      >
        {isMobile ? null : (
          <Grid item xs={12} sm={9} md={9}>
            <Typography>
              <span>{t("overview-page.subtitle")}</span>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={3} md={3}></Grid>
      </Grid>
      <Grid container rowSpacing={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={10}>
          <OverviewFlow></OverviewFlow>
        </Grid>
        <Grid item xs={2}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} sm={9} md={9}>
              <Typography>
                <span>{t("overview-page.menu")}</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => navigate(routerPaths.setupWaterSystems)}
              >
                {t("overview-page.setup-system")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => navigate(routerPaths.setup)}
              >
                {t("overview-page.setup-flow")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => navigate(routerPaths.setupSensors)}
              >
                {t("overview-page.setup-sensors")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ListTables
            rows={sensorsTable}
            tableTitle={t("overview-page.sensors")}
            showViewAll={false}
            viewAll={true}
          ></ListTables>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OverviewPage;
