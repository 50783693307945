import { FETCH_DATA_FROM_MODEL_FAILURE, FETCH_DATA_FROM_MODEL_REQUEST, FETCH_DATA_FROM_MODEL_SUCCESS } from "../actions/realtimeDataFromModel.actions";

const initialState = {
    data: null,
    loading: false,
};

const waterDataFromModel = (state = initialState, action) => {
    switch (action.type) {        
        case FETCH_DATA_FROM_MODEL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_DATA_FROM_MODEL_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case FETCH_DATA_FROM_MODEL_FAILURE:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
};

export default waterDataFromModel;