export const FETCH_WATER_AUTHORITY_REQUEST = "FETCH_WATER_AUTHORITY_REQUEST";
export const FETCH_WATER_AUTHORITY_SUCCESS = "FETCH_WATER_AUTHORITY_SUCCESS";
export const FETCH_WATER_AUTHORITY_FAILURE = "FETCH_WATER_AUTHORITY_FAILURE";
export const UPDATE_WATER_AUTHORITY_REQUEST = "UPDATE_WATER_AUTHORITY_REQUEST";
export const UPDATE_WATER_AUTHORITY_SUCCESS = "UPDATE_WATER_AUTHORITY_SUCCESS";
export const UPDATE_WATER_AUTHORITY_FAILURE = "UPDATE_WATER_AUTHORITY_FAILURE";

export const fetchWaterAuthorityRequest = (id) => {
  return {
    type: FETCH_WATER_AUTHORITY_REQUEST,
    payload: id,
  };
};

export const fetchWaterAuthoritySuccess = (data) => {
  return {
    type: FETCH_WATER_AUTHORITY_SUCCESS,
    payload: data,
  };
};

export const fetchWaterAuthorityFailure = () => {
  return {
    type: FETCH_WATER_AUTHORITY_FAILURE,
  };
};

export const updateWaterAuthority = (payload) => {
  return {
    type: UPDATE_WATER_AUTHORITY_REQUEST,
    payload: payload,
  };
};

export const updateWaterAuthoritySuccess = (data) => {
  return {
    type: UPDATE_WATER_AUTHORITY_SUCCESS,
    payload: data,
  };
};

export const updateWaterAuthorityFailure = () => {
  return {
    type: UPDATE_WATER_AUTHORITY_FAILURE,
  };
};
