import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import circularProgress from "shared-components/src/components/feedback/store/circularProgress.reducer";
import authReducer from "shared-components/src/components/login/store/auth.reducer";
import me from "shared-components/src/components/users/store/users.reducer";
import rootSaga from "../saga/sagas";
import oveview from "./reducers/oveview.reducer";
import planning from "./reducers/planning.reducer";
import waterAuthority from "./reducers/waterAuthorities.reducer";
import waterSystem from "./reducers/waterSystem.reducer";
import waterSystems from "./reducers/waterSystems.reducer";
import weatherForecast from "shared-components/src/components/weatherForecast/store/reducer/weatherForecast.reducer";
import sensors from "./reducers/sensors.reducer";
import waterSensorMeasureData from "./reducers/realtimeSensorMeasure.reducer";
import waterSensorsMeasure from "./reducers/realtimeWater.reducer";
import aggregateMonthMeasureData from "./reducers/realtimeAggregateMonthMeasure.reducer";
import waterDataFromModel from "./reducers/realtimeDataFromModel.reducer";
import { LOGOUT } from "shared-components/src/components/login/store/auth.actions";


const appReducer = combineReducers({
  auth: authReducer,
  user: me,
  circularProgress,
  waterAuthority,
  waterSystem,
  waterSystems,
  weatherForecast,
  planning,
  oveview,
  sensors,
  waterSensorMeasureData,
  waterSensorsMeasure,
  aggregateMonthMeasureData,
  waterDataFromModel,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
