import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MapDrawComponent from "shared-components/src/components/map/mapDraw.component";
import * as Yup from "yup";
import { updateWaterSystemData } from "../../store/actions/waterSystems.actions";

const UpdateWaterSystemComponent = ({ waterSystem, open, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const initialValues = {
        name: waterSystem?.attributes?.name,
        polygons: waterSystem?.attributes?.polygons,
      };
    console.log(initialValues.polygons)
    const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    });

    const handleSave = (value) => {
        const updatedSystem = {
            id: waterSystem.id,
            data: {
              name: value.name,
              polygons: value.polygons
            }
        };
        dispatch(updateWaterSystemData(updatedSystem));
        onClose()
    };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth={'lg'}
      fullWidth={true}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {(formikDialogProps) => (
          <Form>
            <DialogTitle id='dialog-title'>
              {t('setup-water-systems-page.add.button')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='dialog-description'>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={2}
                  style={{ marginTop: '20px' }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <Field
                      as={TextField}
                      label={t('setup-water-systems-page.add.field-form-name')}
                      name='name'
                      variant='outlined'
                      fullWidth
                    />
                    <ErrorMessage name='name' component='div' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MapDrawComponent
                      shapefile={null}
                      polygons={formikDialogProps.values.polygons ?? []}
                      onPolygonsChange={(value) => {
                        formikDialogProps.setFieldValue('polygons', value);
                      }}
                    ></MapDrawComponent>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                {t('setup-water-systems-page.add.cancel-button')}
              </Button>
              <Button type='submit' variant='contained' color='primary'>
                {t('setup-water-systems-page.update-button')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateWaterSystemComponent;
