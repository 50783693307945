import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectVolumesModels from "./SelectVolumesModels.component";
import AreaChartComponent from "shared-components/src/components/charts/areaChart.component"
import { useDispatch, useSelector } from "react-redux";
import { getDataFromModelRequest } from "../../store/actions/realtimeDataFromModel.actions";
import dayjs from "dayjs";

const DataFromModelsComponet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const waterSystemSelected = useSelector((state) => state.waterSystem.data);
  const waterSystems = useSelector((state) => state.waterSystems).data;
  const waterSystem = waterSystems?.find((f) => f.id === waterSystemSelected);
  const dataFromModel = useSelector((state) => state?.waterDataFromModel?.data);
  const [chartData, setChartData] = useState(null);
  const [dataVariable, setDataVariable] = useState('storage');
  const [chartDataByVariable, setChartDataByVariable] = useState(null);

  const handleDatVariable = (data) => {
    setDataVariable(data);
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Data From Model",
      },
    },
  };

  useEffect(() => {
    const ws = waterSystem?.attributes;
    if (ws?.name) {
      dispatch(getDataFromModelRequest(ws));
    }
  }, [waterSystem]);


  useEffect(() => {
    const data = generateDataSetFromDataModel(dataFromModel);
    setChartData(data);
  }, [dataFromModel]);

  useEffect(() => {
    const filteredByVariableDatasets = chartData?.datasets?.filter(item => item.label.toLowerCase().includes(dataVariable.toLowerCase()));
    setChartDataByVariable({ ...chartData, datasets: filteredByVariableDatasets });
  }, [dataVariable]);

  const generateDataSetFromDataModel = (dataFromModel) => {
    const maxMinMean = ['max', 'min', 'mean'];

    const uniqueLabels = new Set();
    const uniqueDatasets = new Map();
    const dataMap = new Map();

    maxMinMean.forEach(mmm => {
      dataFromModel?.data?.forEach(element => {
        const date = dayjs(element.datetime);
        const monthName = date.format('MMMM');
        const year = date.format('YYYY');
        const labelKey = `${monthName} ${year}`;
        uniqueLabels.add(labelKey);

        const id = `${element.measure}_${element.value_type}_${mmm}`;
        const label = element.value_type !== 'real' ? `${element.measure} ${mmm} (${element.value_type})` : `${element.measure} ${mmm} [${element.unit}]`;
        const borderColor = getBorderColor(mmm, element.value_type);
        const backgroundColor = getBackgroundColor(mmm, element.value_type);

        if (!uniqueDatasets.has(id)) {
          uniqueDatasets.set(id, { fill: true, label, borderColor, backgroundColor, data: [] });
          const arrayStart = element.value_type === 'forecast' ? [0, 0, 0, 0, 0, 0] : [];
          dataMap.set(id, arrayStart);
        }
        dataMap.get(id).push({ datetime: element.datetime, value: Number(element[mmm]) });
      });
    });

    dataMap.forEach((dataArray, id) => {
      dataArray.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
      const sortedValues = dataArray.map(item => item.value);
      uniqueDatasets.get(id).data = sortedValues;
    });

    const labels = Array.from(uniqueLabels);
    const datasets = Array.from(uniqueDatasets.values());

    return { labels, datasets };
  };

  const getBorderColor = (maxMinMean, value_type) => {
    if (value_type === 'forecast') {
      return 'rgb(10, 9, 235)';
    }
    if (maxMinMean === 'max') {
      return 'rgb(238, 84, 30)';

    }
    if (maxMinMean === 'min') {
      return 'rgb(144, 111, 207)';
    }
    return 'rgb(20, 230, 121)';
  }

  const getBackgroundColor = (maxMinMean, value_type) => {
    if (value_type === 'forecast') {
      return 'rgba(171,215,230,0.6)'
    }
    if (maxMinMean === 'max') {
      return 'rgba(228,229,231,0.6)'
    }
    return "rgba(228,229,231,0.6)"
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6" fontWeight="bold" paddingBottom={2}>{t("real-time-page.data-from-models")}</Typography>
      <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start">
        <Grid item xs={12} md={2} style={{ paddingTop: "40px" }}>
          <SelectVolumesModels dataModel={handleDatVariable} />
        </Grid>
        <Grid item xs={12} md={10} >
          <Box height="auto" width="auto">
            <AreaChartComponent options={chartOptions} data={chartDataByVariable} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataFromModelsComponet;
