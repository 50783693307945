export const FETCH_DATA_FROM_MODEL_REQUEST = "FETCH_DATA_FROM_MODEL_REQUEST";
export const FETCH_DATA_FROM_MODEL_SUCCESS = "FETCH_DATA_FROM_MODEL_SUCCESS";
export const FETCH_DATA_FROM_MODEL_FAILURE = "FETCH_DATA_FROM_MODEL_FAILURE";

export const getDataFromModelRequest = (system) => {
  return {
    type: FETCH_DATA_FROM_MODEL_REQUEST,
    payload: system,
  };
};

export const getDataFromModelSuccess = (data) => {
  return {
    type: FETCH_DATA_FROM_MODEL_SUCCESS,
    payload: data,
  };
};

export const getDataFromModelFailure = () => {
  return {
    type: FETCH_DATA_FROM_MODEL_FAILURE,
  };
};
