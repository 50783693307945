import { Button, Grid, Icon, Paper } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SetupWaterSystemsComponent from "../components/setupWaterSystems/setupWaterSystems.componet";

const SetupWaterSystemsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button onClick={handleGoBack}>
          <Icon>arrow_back</Icon>
        </Button>
        <h3>{t("setup-water-systems-page.title")}</h3>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            sx={{
              height: "100%",
              paddingX: "20px",
              paddingBottom: "10px",
            }}
          >
            <SetupWaterSystemsComponent></SetupWaterSystemsComponent>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SetupWaterSystemsPage;
