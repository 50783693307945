import { Button, Grid, Icon, Typography, useMediaQuery } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import SetupFlow from "../components/flow/SetupFlow.component";
import { useNavigate } from "react-router-dom";

const SetupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 767px");

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button onClick={handleGoBack}>
          <Icon>arrow_back</Icon>
        </Button>
        <h3>{t("setup-page.title")}</h3>
      </div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ marginBottom: "20px" }}
      >
        {isMobile ? null : (
          <Grid item xs={12} sm={9} md={9}>
            <Typography>
              <span>{t("setup-page.subtitle")}</span>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={3} md={3}></Grid>
      </Grid>
      <Grid container rowSpacing={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <SetupFlow></SetupFlow>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SetupPage;
