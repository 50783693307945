import { Box, Chip, Grid, Paper } from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AreaChartComponent from "shared-components/src/components/charts/areaChart.component";
import LineChartComponent from "shared-components/src/components/charts/lineChart.component";
import MapWaterHomeComponent from "../components/map/MapWaterHome.component";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import HomeAreaChartComponent from "shared-components/src/components/charts/homeAreaChart.component";
import WeatherForecastComponent from "shared-components/src/components/weatherForecast/WeatherForecastNew.component";

const HomePage = () => {
  const { t } = useTranslation();
  const weatherForecast = useSelector((state) => state.weatherForecast).data;
  const waterSystems = useSelector((state) => state.waterSystems);
  const position = waterSystems?.data[0]?.attributes?.polygons[0]?.position[0];

  return (
    <Fragment>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        paddingTop={2}
      >
        <Grid item xs={12} md={4}>
          {weatherForecast && (
            <Paper
              variant="outlined"
              sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
            >
              <h3>{t("home.weather-forecast")}</h3>
              <div style={{ minHeight: "320px" }}>
                <WeatherForecastComponent position={position} />
              </div>
              <h3>{t("home.months-water-forecast")}</h3>
              <Box
                width="100%"
                height="500px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="10px"
              >
                {/* <Chip
                      icon={<PrecisionManufacturingIcon/>}
                      label="Under development"
                      variant="outlined"
                      color="warning"
                    /> */}
                {/* <LineChartComponent
                    data={weatherForecastChartData}
                    ></LineChartComponent> */}
                <HomeAreaChartComponent />
              </Box>
            </Paper>
          )}
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                sx={{ height: "100%", paddingX: "20px", paddingBottom: "20px" }}
              >
                <h3>{t("home.oveview")}</h3>
                <MapWaterHomeComponent></MapWaterHomeComponent>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper
                variant="outlined"
                sx={{
                  height: "500px",
                  paddingX: "20px",
                  paddingBottom: "20px",
                }}
              >
                <h3>{t("home.mounths-flow-projection")}</h3>
                {/* <AreaChartComponent></AreaChartComponent> */}
                <Box
                  width="100%"
                  height="400px"
                  border="0.5px solid lightgrey"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="10px"
                >
                  <Chip
                    icon={<PrecisionManufacturingIcon />}
                    label="Under development"
                    variant="outlined"
                    color="warning"
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default HomePage;
