import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import SharedLayout from "shared-components/src/layout/SharedLayout";
import i18n from "shared-components/src/utils/i18n";
import "./App.css";
import translation_en from "./assets/i18n/en.json";
import translation_fr from "./assets/i18n/fr.json";
import translation_it from "./assets/i18n/it.json";
import Router from "./router/router";
import store from "./store/store";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import palette_custom from "./palette";
import SetDummyData from "./utils/setDummyData";

const theme = createTheme({
  palette: {
    primary: {
      main: palette_custom.primary,
    },
    secondary: {
      main: palette_custom.secondary,
    },
    error: {
      main: palette_custom.error,
    },
    warning: {
      main: palette_custom.accent,
    },
    info: {
      main: palette_custom.text,
    },
    success: {
      main: palette_custom.check,
    },
    risk: {
      main: palette_custom.risk,
    },
    late: {
      main: palette_custom.late,
    },
    optimal: {
      main: palette_custom.optimal,
    },
    marker: {
      main: palette_custom.marker,
    },
  },
});

function App() {
  i18n.addResourceBundle("en", "translation", translation_en, true, true);
  i18n.addResourceBundle("fr", "translation", translation_fr, true, true);
  i18n.addResourceBundle("it", "translation", translation_it, true, true);

  return (
    <Provider store={store}>
      <SetDummyData></SetDummyData>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SharedLayout />
          <Router></Router>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
