export const FETCH_ALL_WATER_SENSORS_MEASURE_REQUEST =
  "FETCH_ALL_WATER_SENSORS_MEASURE_REQUEST";
export const FETCH_ALL_WATER_SENSORS_MEASURE_SUCCESS =
  "FETCH_ALL_WATER_SENSORS_MEASURE_SUCCESS";
export const FETCH_ALL_WATER_SENSORS_MEASURE_FAILURE =
  "FETCH_ALL_WATER_SENSORS_MEASURE_FAILURE";

export const fetchWaterSensorsMeasureRequest = (system) => {
  return {
    type: FETCH_ALL_WATER_SENSORS_MEASURE_REQUEST,
    payload: system,
  };
};

export const fetchWaterSensorsMeasureSuccess = (data) => {
  return {
    type: FETCH_ALL_WATER_SENSORS_MEASURE_SUCCESS,
    payload: data,
  };
};

export const fetchWaterSensorsMeasureFailure = () => {
  return {
    type: FETCH_ALL_WATER_SENSORS_MEASURE_FAILURE,
  };
};
