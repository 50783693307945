import {
  RESET_WATER_SYSTEM,
  SET_WATER_SYSTEM,
} from "../actions/waterSystem.actions";

const initialState = {
  data: null,
};

const waterSystem = (state = initialState, action) => {
  switch (action.type) {
    case SET_WATER_SYSTEM:
      return {
        ...state,
        data: action.payload,
      };
    case RESET_WATER_SYSTEM:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export default waterSystem;
