import {
    FETCH_ALL_WATER_SENSORS_MEASURE_FAILURE,
    FETCH_ALL_WATER_SENSORS_MEASURE_REQUEST,
    FETCH_ALL_WATER_SENSORS_MEASURE_SUCCESS
} from "../actions/realtimeWater.actions";

const initialState = {
    data: null,
    loading: false,
};

const waterSensorsMeasure = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_WATER_SENSORS_MEASURE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_ALL_WATER_SENSORS_MEASURE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case FETCH_ALL_WATER_SENSORS_MEASURE_FAILURE:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
};

export default waterSensorsMeasure;