export const FETCH_OVEVIEW_REQUEST = "FETCH_OVEVIEW_REQUEST";
export const FETCH_OVEVIEW_SUCCESS = "FETCH_OVEVIEW_SUCCESS";
export const FETCH_OVEVIEW_FAILURE = "FETCH_OVEVIEW_FAILURE";

export const fetchOveviewDataRequest = () => {
  return {
    type: FETCH_OVEVIEW_REQUEST,
  };
};

export const fetchOveviewDataSuccess = (data) => {
  return {
    type: FETCH_OVEVIEW_SUCCESS,
    payload: data,
  };
};

const fetchOveviewDataFailure = () => {
  return {
    type: FETCH_OVEVIEW_FAILURE,
  };
};

export const fetchOveviewData = () => {
  return async (dispatch) => {
    dispatch(fetchOveviewDataRequest());

    try {
      const response = await fetch("your-api-endpoint");
      const data = await response.json();
      dispatch(fetchOveviewDataSuccess(data));
    } catch (error) {
      dispatch(fetchOveviewDataFailure());
    }
  };
};
