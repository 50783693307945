import {
    FETCH_WATER_SENSOR_MEASURE_FAILURE,
    FETCH_WATER_SENSOR_MEASURE_REQUEST,
    FETCH_WATER_SENSOR_MEASURE_SUCCESS
} from "../actions/realtimeSensorMeasure.actions";

const initialState = {
    data: null,
    loading: false
};

const waterSensorMeasureData = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WATER_SENSOR_MEASURE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_WATER_SENSOR_MEASURE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case FETCH_WATER_SENSOR_MEASURE_FAILURE:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
};

export default waterSensorMeasureData;