export const SET_WATER_SYSTEM = "SET_WATER_SYSTEM";
export const RESET_WATER_SYSTEM = "RESET_WATER_SYSTEM";

export const setWaterSystem = (waterSystem) => {
  return {
    type: SET_WATER_SYSTEM,
    payload: waterSystem,
  };
};

export const resetWaterSystem = () => {
  return {
    type: RESET_WATER_SYSTEM,
  };
};
