import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomizedTables, {
  createData,
} from "shared-components/src/components/displayData/CustomizedTables.component";
import MapDrawComponent from "shared-components/src/components/map/mapDraw.component";
import * as Yup from "yup";
import { addWaterSystemData, deleteWaterSystemData } from "../../store/actions/waterSystems.actions";
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from "shared-components/src/components/confirmationModal/confirmationModal.component";
import UpdateWaterSystemComponent from "./updateWaterSystem.component";

const SetupWaterSystemsComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState(null);
  const [systemToUpdate, setSystemToUpdate] = useState(null);
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false); 

  const waterSystems = useSelector((state) => state?.waterSystems?.data);

  const initialValues = {
    name: null,
    polygons: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (value) => {
    if (value) {
      const data = {
        name: value.name,
        polygons: value.polygons,
      };
      dispatch(addWaterSystemData(data));
    }
    setOpen(false);
  };

  const deleteWaterSystem = (value) => {
    if (value) {
    dispatch(deleteWaterSystemData(value));
    }
  };

  const handleDeleteClick = (value) => {
    setObjectToDelete(value);
    setModalOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteWaterSystem(objectToDelete);
    setModalOpen(false);
  };

  const handleCancelDelete = () => {
    setObjectToDelete(null);
    setModalOpen(false);
  };

  /* const updateWaterSystem = (value) => {
    if (value) {
    }
  }; */

  const handleUpdateClick = (waterSystem) => {
    setSystemToUpdate(waterSystem);
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setSystemToUpdate(null);
    setUpdateDialogOpen(false);
  };

  const createRowDataObject = (data) => {
    const attributes = {
      name: data?.attributes?.name,
      position: `lat: ${data?.attributes?.polygons?.[0]?.position?.[0]?.[0]}, lng: ${data?.attributes?.polygons?.[0]?.position?.[0]?.[1]}`,
      createdAt: data?.attributes?.createdAt,
      updatedAt: data?.attributes?.updatedAt,
    };
    const result = Object.keys(attributes).map((key) =>
      createData(key, attributes[key]),
    );
    return result;
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>
              {t(
                "setup-water-systems-page.add.title",
              )}
            </p>
            <h5 onClick={handleClickOpen} style={{ cursor: "pointer" }}>
              {t("setup-water-systems-page.add.button")}
            </h5>
          </div>
        </Grid>
        {waterSystems.map((waterSystem, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              key={index}
              style={{
                background: "#f6f6f6",
                width: "100%",
                paddingBottom: "20px",
                marginBottom: "60px",
                marginLeft: "5px",
                paddingRight: "15px",
              }}
            >
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                  }}
                >
                  <div>{`${waterSystem.attributes.name}`}</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      fontWeight: "bold",
                    }}
                  >
                    <div
                      onClick={() => handleUpdateClick(waterSystem)}
                      style={{ cursor: "pointer", marginRight: "10px" }}
                    >
                      <CreateIcon fontSize="small"/>
                    </div>
                    <div
                      onClick={() => handleDeleteClick(waterSystem)}
                      style={{ cursor: "pointer" }}
                    >
                      <DeleteIcon fontSize="small"/>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <CustomizedTables
                  rows={createRowDataObject(waterSystem)}
                ></CustomizedTables>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
        fullWidth={true}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {(formikDialogProps) => (
            <Form>
              <DialogTitle id="dialog-title">{t("setup-water-systems-page.add.button")}</DialogTitle>
              <DialogContent>
                <DialogContentText id="dialog-description">
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    style={{ marginTop: "20px" }}
                  ><Grid item xs={12} sm={12} md={6}>
                      <Field
                        as={TextField}
                        label={t("setup-water-systems-page.add.field-form-name")}
                        name="name"
                        variant="outlined"
                        fullWidth
                      />
                      <ErrorMessage name="name" component="div" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MapDrawComponent
                        shapefile={null}
                        polygons={formikDialogProps.polygons ?? []}
                        onPolygonsChange={((value) => {
                          formikDialogProps.setFieldValue(
                            "polygons",
                            value,
                          )
                        })}
                      ></MapDrawComponent>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t("setup-water-systems-page.add.cancel-button")}</Button>
                <Button type="submit" variant="contained" color="primary">
                  {t("setup-water-systems-page.add.add-button")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      {systemToUpdate && (
        <UpdateWaterSystemComponent
          waterSystem={systemToUpdate}
          open={isUpdateDialogOpen}
          onClose={handleUpdateDialogClose}
        />
      )}
      <ConfirmationModal
          open={isModalOpen}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          title={t("confirmation-modal.title")}
          message={t("confirmation-modal.message")}
          cancelText={t("confirmation-modal.cancel")}
          confirmText={t("confirmation-modal.confirm")}
        />
    </Fragment>
  );
};

export default SetupWaterSystemsComponent;