import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import authService from "shared-components/src/components/login/auth.service";
import { loginSuccess } from "shared-components/src/components/login/store/auth.actions";
import MainLayout from "../layout/main.layout";
import HomePage from "../pages/home.page";
import LoginPage from "../pages/login.page";
import RealtimePage from "../pages/realtime.page";
import SetupPage from "../pages/setup.page";
import WikiPage from "../pages/wiki.page";
import { routerPaths } from "./routerPaths";
import OverviewPage from "../pages/overview.page";
import SetupSensorsPage from "../pages/setupSenors.page";
import SetupWaterSystemsPage from "../pages/setupWaterSystems.page";
// import * as apiConfig from "./../config/api";
// import { initializeApiService } from "shared-components/src/services/apiService";

// initializeApiService(apiConfig);

const Router = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const isAuthenticated = authService.isAuthenticated(token);

  useEffect(() => {
    const isRemember = authService.isRemember();

    if (isRemember) {
      // Esegui la chiamata di verifica del token lato server
      // api.verifyToken(rememberMeToken)
      //   .then((response) => {
      //     if (response.data.valid) {
      dispatch(loginSuccess(isRemember));
      //   }
      // })
      // .catch((error) => {
      //   dispatch(loginFailure(error));
      // });
    }
  }, []);

  return (
    <Routes>
      <Route
        index
        element={
          isAuthenticated ? (
            <Navigate to={routerPaths.home} />
          ) : (
            <Navigate to={routerPaths.login} />
          )
        }
      />
      <Route path={routerPaths.login} element={<LoginPage />} />
      {isAuthenticated ? (
        <Route
          element={
            <MainLayout>
              <Outlet />
            </MainLayout>
          }
        >
          <Route path={routerPaths.home} element={<HomePage />} />
          <Route path={routerPaths.setup} element={<SetupPage />} />
          <Route
            path={routerPaths.setupSensors}
            element={<SetupSensorsPage />}
          />
          <Route
            path={routerPaths.setupWaterSystems}
            element={<SetupWaterSystemsPage />}
          />
          <Route path={routerPaths.overview} element={<OverviewPage />} />
          <Route path={routerPaths.realtime} element={<RealtimePage />} />
          <Route path={routerPaths.wiki} element={<WikiPage />} />
        </Route>
      ) : (
        <Route path="/*" element={<Navigate to={""} />} />
      )}
    </Routes>
  );
};

export default Router;
