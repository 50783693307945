import { Button, Grid, Icon, Paper } from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddSensorsComponent from "../components/addSensors/addSensors.component";
import ConfirmationModal from "shared-components/src/components/confirmationModal/confirmationModal.component";
import { useDispatch, useSelector } from "react-redux";
import { deleteWaterSystemData, /* updateWaterSystemData */ } from "../store/actions/waterSystems.actions";

const SetupSensorsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const waterSystemSelected = useSelector((state) => state.waterSystem.data)
  const waterSystem = useSelector((state) => state.waterSystems.data).find(
    (w) => w.id === waterSystemSelected
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  /* const onSubmit = (values) => {
    dispatch(updateWaterSystemData(values));
    handleGoBack();
  }; */

  const onDelete = (values) => {
    dispatch(deleteWaterSystemData(values));
    handleGoBack();
  };

  const handleConfirmDelete = () => {
    onDelete(waterSystem);
    setModalOpen(false);
  };

  const handleCancelDelete = () => {
    setModalOpen(false);
  };

  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button onClick={handleGoBack}>
          <Icon>arrow_back</Icon>
        </Button>
        <h3>{t("setup-sensors-page.title")}</h3>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            sx={{
              height: "100%",
              paddingX: "20px",
              paddingBottom: "10px",
            }}
          >
            <AddSensorsComponent></AddSensorsComponent>
          </Paper>
        </Grid>
      </Grid>
      {/* <FieldFormComponent onSubmit={onSubmit}></FieldFormComponent> */}
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={t("sensors_form.confirmational_modal.title")}
        message={t("sensors_form.confirmational_modal.message")}
        cancelText={t("sensors_form.confirmational_modal.cancel_text")}
        confirmText={t("sensors_form.confirmational_modal.confirm_text")}
      />
    </Fragment>
  );
};

export default SetupSensorsPage;
