import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CustomizedTables from "shared-components/src/components/displayData/CustomizedTables.component";

const LastDataComponent = ({ t, sensor, handleChangeMeasure, sensorData }) => {

  const deviceID = useSelector((state) => state?.waterSensorsMeasure?.data?.[0]?.deviceID)


  return (
    <>
      <Typography variant='body1' fontWeight='bold'>
        {t('real-time-page.last-data-acquisition')}
      </Typography>
      <Box height='auto' width='100%' marginTop={2}>
        <Stack spacing={2}>
          <FormControl sx={{ width: "150px" }}>
            <InputLabel>{t("real-time-page.sensor-selector-label")}</InputLabel>
            <Select
              labelId='sensor-select-label'
              id='sensor-select'
              value={sensor}
              label={t("real-time-page.sensor-selector-label")}
              onChange={handleChangeMeasure}
            >
              <MenuItem value={deviceID}>
                {deviceID}
              </MenuItem>
            </Select>
          </FormControl>
          <Box>
          {sensor && (
            <CustomizedTables rows={sensorData} tableTitle={t("real-time-page.table-data-title")}/>
          )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default LastDataComponent;
