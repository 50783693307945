export const FETCH_WATER_SENSOR_MEASURE_REQUEST = "FETCH_WATER_SENSOR_MEASURE_REQUEST";
export const FETCH_WATER_SENSOR_MEASURE_SUCCESS = "FETCH_WATER_SENSOR_MEASURE_SUCCESS";
export const FETCH_WATER_SENSOR_MEASURE_FAILURE = "FETCH_WATER_SENSOR_MEASURE_FAILURE";

export const fetchWaterSensorMeasureDataRequest = (systemName, sensorMeasure) => {
  return {
    type: FETCH_WATER_SENSOR_MEASURE_REQUEST,
    payload: {systemName, sensorMeasure}
  };
};

export const fetchWaterSensorMeasureDataSuccess = (data) => {
  return {
    type: FETCH_WATER_SENSOR_MEASURE_SUCCESS,
    payload: data,
  };
};

export const fetchWaterSensorMeasureDataFailure = (error) => {
  return {
    type: FETCH_WATER_SENSOR_MEASURE_FAILURE,
    payload: error
  };
};