export const FETCH_SENSORS_REQUEST = "FETCH_SENSORS_REQUEST";
export const FETCH_SENSORS_SUCCESS = "FETCH_SENSORS_SUCCESS";
export const FETCH_SENSORS_FAILURE = "FETCH_SENSORS_FAILURE";
export const ADD_SENSOR = "ADD_SENSOR";
export const ADD_SENSOR_SUCCESS = "ADD_SENSOR_SUCCESS";
export const ADD_SENSOR_FAILURE = "ADD_SENSOR_FAILURE";
export const UPDATE_SENSOR = "UPDATE_SENSOR";
export const UPDATE_SENSOR_SUCCESS = "UPDATE_SENSOR_SUCCESS";
export const UPDATE_SENSOR_FAILURE = "UPDATE_SENSOR_FAILURE";
export const DELETE_SENSOR = "DELETE_SENSOR";
export const DELETE_SENSOR_SUCCESS = "DELETE_SENSOR_SUCCESS";
export const DELETE_SENSOR_FAILURE = "DELETE_SENSOR_FAILURE";
export const ADD_COMMAND_REQUEST = "ADD_COMMAND_REQUEST";
export const ADD_COMMAND_SUCCESS = "ADD_COMMAND_SUCCESS";
export const ADD_COMMAND_FAILURE = "ADD_COMMAND_FAILURE";
export const FETCH_COMMAND_REQUEST = "FETCH_COMMAND_REQUEST";
export const FETCH_COMMAND_SUCCESS = "FETCH_COMMAND_SUCCESS";
export const FETCH_COMMAND_FAILURE = "FETCH_COMMAND_FAILURE";

export const addSensorRequest = (data) => {
  return {
    type: ADD_SENSOR,
    payload: data,
  };
};

export const addSensorSuccess = (data) => {
  return {
    type: ADD_SENSOR_SUCCESS,
    payload: data,
  };
};

export const addSensorFailure = () => {
  return {
    type: ADD_SENSOR_FAILURE,
  };
};

export const deleteSensorRequest = (data) => {
  return {
    type: DELETE_SENSOR,
    payload: data,
  };
};

export const deleteSensorSuccess = (data) => {
  return {
    type: DELETE_SENSOR_SUCCESS,
    payload: data,
  };
};

export const deleteSensorFailure = () => {
  return {
    type: DELETE_SENSOR_FAILURE,
  };
};

export const updateSensorRequest = (data) => {
  return {
    type: UPDATE_SENSOR,
    payload: data
  }
};

export const updateSensorSuccess = (data) => {
  return {
    type: UPDATE_SENSOR_SUCCESS,
    payload: data
  }
};

export const updateSensorFailure = () => {
  return {
    type: UPDATE_SENSOR_FAILURE
  }
};

export const addCommandRequest = (data) => {
  return {
    type: ADD_COMMAND_REQUEST,
    payload: data,
  };
};

export const addCommandSuccess = () => {
  return {
    type: ADD_COMMAND_SUCCESS,
  };
};

export const addCommandFailure = () => {
  return {
    type: ADD_COMMAND_FAILURE,
  };
};

export const fetchCommandStatusRequest = (payload) => {
  return {
    type: FETCH_COMMAND_REQUEST,
    payload: payload,
  };
};

export const fetchCommandStatusSuccess = (data) => {
  return {
    type: FETCH_COMMAND_SUCCESS,
    payload: data,
  };
};

export const fetchCommandStatusFailure = () => {
  return {
    type: FETCH_COMMAND_FAILURE,
  };
};
