export const FETCH_WATER_SYSTEMS_REQUEST = "FETCH_WATER_SYSTEMS_REQUEST";
export const FETCH_WATER_SYSTEMS_SUCCESS = "FETCH_WATER_SYSTEMS_SUCCESS";
export const FETCH_WATER_SYSTEMS_FAILURE = "FETCH_WATER_SYSTEMS_FAILURE";
export const ADD_WATER_SYSTEM = "ADD_WATER_SYSTEM";
export const ADD_WATER_SYSTEM_SUCCESS = "ADD_WATER_SYSTEM_SUCCESS";
export const ADD_WATER_SYSTEM_FAILURE = "ADD_WATER_SYSTEM_FAILURE";
export const UPDATE_WATER_SYSTEM = "UPDATE_WATER_SYSTEM";
export const DELETE_WATER_SYSTEM = "DELETE_WATER_SYSTEM";
export const DELETE_WATER_SYSTEM_SUCCESS = "DELETE_WATER_SYSTEM_SUCCESS";
export const DELETE_WATER_SYSTEM_FAILURE = "DELETE_WATER_SYSTEM_FAILURE";


export const fetchWaterSystemsRequest = (payload) => {
  return {
    type: FETCH_WATER_SYSTEMS_REQUEST,
    payload: payload,
  };
};

export const fetchWaterSystemsSuccess = (data) => {
  return {
    type: FETCH_WATER_SYSTEMS_SUCCESS,
    payload: data,
  };
};

export const fetchWaterSystemsFailure = () => {
  return {
    type: FETCH_WATER_SYSTEMS_FAILURE,
  };
};

export const addWaterSystemData = (waterSystem) => {
  return {
    type: ADD_WATER_SYSTEM,
    payload: waterSystem,
  };
};

export const updateWaterSystemData = (waterSystem) => {
  return {
    type: UPDATE_WATER_SYSTEM,
    payload: waterSystem,
  };
};

export const deleteWaterSystemData = (waterSystem) => {
  return {
    type: DELETE_WATER_SYSTEM,
    payload: waterSystem
  };
};
