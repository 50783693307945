import { Box, Divider, Paper, Typography, useMediaQuery } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Section = ({ titleKey, descriptionKey, titleAddition }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Typography fontWeight="bold">
        {t(titleKey)} - {t(titleAddition)}
      </Typography>
      <Typography marginBottom={1}>{t(descriptionKey)}</Typography>
    </Fragment>
  );
};

const WikiPage = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 767px");

  const sectionsHome = [
    {
      titleAddition: "wikiPage.home.menu",
      descriptionKey: "wikiPage.home.menu-description",
    },
    {
      titleAddition: "wikiPage.home.weather",
      descriptionKey: "wikiPage.home.weather-description",
    },
    {
      titleAddition: "wikiPage.home.overview",
      descriptionKey: "wikiPage.home.overview-description",
    },
    {
      titleAddition: "wikiPage.home.availability",
      descriptionKey: "wikiPage.home.availability-description",
    },
  ];

  const sectionsProjection = [
    {
      titleAddition: "wikiPage.projection.title",
      descriptionKey: "wikiPage.projection.description",
    },
  ];

  const sectionsOverview = [
    {
      titleAddition: "wikiPage.overview.menu",
      descriptionKey: "wikiPage.overview.menu-description",
    },
    {
      titleAddition: "wikiPage.overview.edit-system",
      descriptionKey: "wikiPage.overview.edit-system-description",
    },
    {
      titleAddition: "wikiPage.overview.configure-diagram",
      descriptionKey: "wikiPage.overview.configure-diagram-description",
    },
    {
      titleAddition: "wikiPage.overview.configure-sensors",
      descriptionKey: "wikiPage.overview.configure-sensors-description",
    },
  ];

  const sectionsRealTime = [
    {
      titleAddition: "wikiPage.realtime.menu",
      descriptionKey: "wikiPage.realtime.menu-description",
    },
    {
      titleAddition: "wikiPage.realtime.historical-data",
      descriptionKey: "wikiPage.realtime.historical-data-description",
    },
    {
      titleAddition: "wikiPage.realtime.sensor-data",
      descriptionKey: "wikiPage.realtime.sensor-data-description",
    },
  ];

  const sectionsWiki = [
    {
      titleAddition: "wikiPage.wiki.menu",
      descriptionKey: "wikiPage.wiki.menu-description",
    },
  ];

  return (
    <Fragment>
      <h3>{t("wikiPage.title")}</h3>
      {isMobile ? null : <Typography>{t("wikiPage.subtitle")}</Typography>}
      <Box width="100%" height="auto" mt={3}>
        <Paper variant="elevation" sx={{ padding: 2 }}>
          <Box marginBottom={2}>
            {sectionsHome.map((section, index) => (
              <Section
                key={index}
                titleKey={`wikiPage.home.title`}
                titleAddition={section.titleAddition}
                descriptionKey={section.descriptionKey}
              />
            ))}
          </Box>
          <Divider />
          <Box marginBottom={2} mt={2}>
            {sectionsProjection.map((section, index) => (
              <Section
                key={index}
                titleKey={`wikiPage.projection.title`}
                titleAddition={section.titleAddition}
                descriptionKey={section.descriptionKey}
              />
            ))}
          </Box>
          <Divider />
          <Box marginBottom={2} mt={2}>
            {sectionsOverview.map((section, index) => (
              <Section
                key={index}
                titleKey={`wikiPage.overview.title`}
                titleAddition={section.titleAddition}
                descriptionKey={section.descriptionKey}
              />
            ))}
          </Box>
          <Divider />
          <Box marginBottom={2} mt={2}>
            {sectionsRealTime.map((section, index) => (
              <Section
                key={index}
                titleKey={`wikiPage.realtime.title`}
                titleAddition={section.titleAddition}
                descriptionKey={section.descriptionKey}
              />
            ))}
          </Box>
          <Divider />
          <Box marginBottom={2} mt={2}>
            {sectionsWiki.map((section, index) => (
              <Section
                key={index}
                titleKey={`wikiPage.wiki.title`}
                titleAddition={section.titleAddition}
                descriptionKey={section.descriptionKey}
              />
            ))}
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default WikiPage;
