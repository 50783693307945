import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "6-month projection",
    },
  },
};

const labels = [];

export const data = {
  labels,
  datasets: [ ],
};

const AreaChartComponent = (props) => {
  const chartData = props?.data ?? data;
  const chartOptions = props?.options ?? options;

  return <Line options={chartOptions} data={chartData}/>;
};

export default AreaChartComponent;


///Data Example
// const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

// export const data = {
//   labels,
//   datasets: [
//     {
//       fill: true,
//       label: "Historical",
//       data: [17, 17, 15, 14, 13, 13, 12, 12, 13, 14, 15, 17, 17],
//       borderColor: "rgb(203, 47, 47)",
//       backgroundColor: "rgba(228,229,231,0.6)",
//     },
//     {
//       fill: true,
//       label: "Avarage",
//       data: [37, 37, 30, 29, 26, 24, 22, 22, 24, 29, 30, 37, 37],
//       borderColor: "rgb(205, 195, 231)",
//       backgroundColor: "rgba(228,229,231,0.6)",
//     },
//     {
//       fill: true,
//       label: "Forecast",
//       data: [null, null, null, null, null, 13, 54, 64, 64, 68, 77, 77],
//       borderColor: "rgb(54, 80, 91)",
//       backgroundColor: "rgba(171,215,230,0.6)",
//     },
//   ],
// };