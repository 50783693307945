import axios from "axios";
import { API_AGGREGATE_MONTH_MEASURE } from "../../config/api";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { showAlertInfo } from "shared-components/src/components/feedback/store/alertInfo.actions";
import { alertInfoTypes } from "shared-components/src/components/feedback/alertInfoTypes.enum";
import { hideProgress, showProgress } from "shared-components/src/components/feedback/store/circularProgress.actions";
import { FETCH_AGGREGATE_MONTH_MEASURE_REQUEST, fetchAggregateMonthMeasureDataFailure, fetchAggregateMonthMeasureDataSuccess } from "../../store/actions/realtimeAggregateMonthMeasure.actions";
import dayjs from "dayjs";

const getAggregateMonthMeasureData = async (
    token,
    name,
    end_time
) => {
    const url = `${API_AGGREGATE_MONTH_MEASURE}?name=${name}&time=${end_time}`;
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
};

function* handleAggregateMonthMeasureData(action) {
    try {
        yield put(showProgress());
        const token = yield select((state) => state.auth.token);
        const system = action?.payload.systemName;
        const name = action?.payload.sensorMeasure;
        const end_time = dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]");
        const response = yield call(
            getAggregateMonthMeasureData,
            token,
            system,
            name,
            end_time
        );
        yield put(fetchAggregateMonthMeasureDataSuccess(response));
        yield put(hideProgress());
    } catch (error) {
        yield put(
            showAlertInfo({ type: alertInfoTypes.ERROR, message: error.message }),
          );
        yield put(hideProgress());
        yield put(fetchAggregateMonthMeasureDataFailure(error.message)); 
    }
};

export function* watchGetAggregateMonthMeasure() {
    yield takeLatest(FETCH_AGGREGATE_MONTH_MEASURE_REQUEST, handleAggregateMonthMeasureData);
};