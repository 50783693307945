import {
    FETCH_AGGREGATE_MONTH_MEASURE_FAILURE,
    FETCH_AGGREGATE_MONTH_MEASURE_SUCCESS,
    FETCH_AGGREGATE_MONTH_MEASURE_REQUEST
} from "../actions/realtimeAggregateMonthMeasure.actions";

const initialState = {
    data: null,
    loading: false
};

const aggregateMonthMeasureData = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AGGREGATE_MONTH_MEASURE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_AGGREGATE_MONTH_MEASURE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case FETCH_AGGREGATE_MONTH_MEASURE_FAILURE:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
};

export default aggregateMonthMeasureData;